import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Logo from "../../components/logo"

const Language = "de-DE"

const Greeting = () => (
    <Layout>
        <SEO title="Happy Easter" lang={Language} />
        <div className="">
            <Logo className="mx-auto h-auto w-48 pt-8" route="https://candide.berlin/startseite" />
            <div className="pt-8 max-w-xl mx-auto text-center">

                <div class="bg-white overflow-hidden shadow sm:rounded-lg">
                    <div class="px-4 py-5 sm:p-6">
                        <div className="vimeo">
                            <iframe 
                                src="https://player.vimeo.com/video/406731826" 
                                title="Happy Easter"
                                width="640" 
                                height="564" 
                                frameB
                                order="0" allow="autoplay; fullscreen" 
                                allowFullScreen>
                            </iframe>
                        </div>
                    </div>
                </div>
                <h1 className="mt-8 text-6xl font-display">Frohe Ostern</h1>
                <p className="text-2xl tracking-wide font-display text-canblue">von Scotty the Blue Bunny und Candide Schokoladen</p>

            </div>
        </div>
  </Layout>

)

export default Greeting

